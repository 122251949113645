window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

require('./php');
require('@fortawesome/fontawesome-free/js/fontawesome.min')
require('@fortawesome/fontawesome-free/js/solid.min')
window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap') 
window.Flow = require('@flowjs/flow.js');
require('bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js')
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min.js')
require('select2/dist/js/select2.min.js')
require('select2/dist/js/i18n/ja.js')
window.BigNumber = require('bignumber.js')
window.moment = require('moment')
window.moment.locale('ja')

let toastContainer
let show_toast = function(type, message)
{
    if(!toastContainer)
    {
        toastContainer = document.querySelector('.toast-container')
    }

    let html = document.getElementById('cloneToast-' + type).cloneNode(true)

    if(html)
    {
        html.querySelector('.toast-body').textContent = message
        toastContainer.appendChild(html)
        let toast = new bootstrap.Toast(html)
        toast.show()
    }
}
window.show_toast = show_toast

window.addEventListener('load', function()
{
    const onclick = function()
    {
        const $form = this.closest('form');
        let name = this.dataset.name;
        let value = this.dataset.value;
        let action = this.dataset.action;

        if(!name)
        {
            if(this.classList.contains('btnBack'))
            {
                name = '_back';
            }
            else if(this.classList.contains('btnNext'))
            {
                name = '_next';
            }
        }

        if(!value)
        {
            value = '1';
        }

        $form.insertAdjacentHTML('beforeend', '<input type="hidden" name="' + name + '" value="' + value + '" />')

        if (action)
        {
            $form.action = window.php.rtrim(action, '/');
        }

        $form.dispatchEvent(new Event('submit'))
        $form.submit();

        return false;
    };
    
    document.querySelectorAll(".btnSubmit, .btnBack, .btnNext").forEach(function(button)
    {
        button.addEventListener('click', onclick);
    });

    let toasts = [].slice.call(document.querySelectorAll('.toast'))
    toasts = toasts.map(function (toast) {
        return new window.bootstrap.Toast(toast)
    })
    toasts.map(function(toast)
    {
        toast.show()
    })

    document.querySelectorAll('.trJump').forEach(function(tr)
    {
        if(tr.dataset.url)
        {
            tr.addEventListener('click', function(e)
            {
                if(e.target.nodeName.toLowerCase() === 'a')
                {
                }
                else if(tr.dataset.target)
                {
                    window.open(tr.dataset.url, tr.dataset.target);
                }
                else
                {
                    window.location.assign(tr.dataset.url)
                }
            })
        }
    })
})

$(function()
{
    $('.form-select:not(.form-select2,.form-manual)').select2({
        theme: "bootstrap-5",
        minimumResultsForSearch: -1,
        dropdownAutoWidth: false,
        width: 'auto',
    })
    $('.form-select.form-select2:not(.form-manual)').select2({
        theme: "bootstrap-5",
        dropdownAutoWidth: false,
        width: 'auto',
    })
})
